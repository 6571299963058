import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "@tt/vue-auth0";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// auth check
router.beforeEach(authGuard);

export default router;
